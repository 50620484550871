import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeLink from './HomeLink';
import UserProfileDropDown from './UserProfileDropDown';
import MobileNavMenu from './MobileNavMenu';
import useIsMobile from '../../utils/customHooks/useIsMobile';
import useRouteList from '../../utils/customHooks/useRouteList';

const LoggedOutActions = ({ isMobile }) => (
	<>
		<Button
			color="secondary"
			component={Link}
			disableElevation
			to="/identity/login"
		>
			Login
		</Button>
		{!isMobile && (
			<Button
				color="secondary"
				component={Link}
				disableElevation
				sx={{ ml: 2 }}
				to="/identity/registration"
				variant="contained"
			>
				Check Eligibility
			</Button>
		)}
	</>
);
LoggedOutActions.propTypes = {
	isMobile: PropTypes.bool.isRequired,
};

const LoggedInActions = ({ user }) => {
	const cartIgnoredCodes = [
		'facilityRemove',
		'cancelAll',
		'cancelAllUser',
		'reactivateAll',
		'reactivateAllUser',
	];

	const storageKey = `fitsubscriptionCart-${user.fitnessId}`;
	const cart = JSON.parse(window.localStorage.getItem(storageKey));

	const cartCount =
		cart?.subscriptionItems
			.filter(i => !cartIgnoredCodes.includes(i.code))
			.map(i => (i.kits ? i.kits.filter(kit => !kit.selected).length : 1))
			.reduce((count, itemCount) => count + itemCount, 0) ?? 0;

	return (
		<>
			<IconButton href="/subscription" aria-label="cart">
				<Badge badgeContent={cartCount} color="error">
					<ShoppingCartIcon />
				</Badge>
			</IconButton>
			<UserProfileDropDown user={user} />
		</>
	);
};
LoggedInActions.propTypes = {
	user: PropTypes.object.isRequired,
};

const TopBar = ({ measurements, user = {} }) => {
	const isMobile = useIsMobile();
	const routes = useRouteList();

	return (
		<Box sx={{ boxShadow: '0px 2px 2px -1px rgba(0, 0, 0, 0.07) ' }}>
			<Container>
				<Toolbar
					disableGutters
					sx={{
						justifyContent: 'space-between',
						minHeight: {
							xs: `${measurements.topBarHeightMobile}px !important`,
							sm: `${measurements.topBarHeight}px !important`,
						},
						py: 1,
					}}
				>
					<Stack direction="row" spacing={1}>
						{isMobile && routes.length > 0 && (
							<MobileNavMenu
								measurements={measurements}
								menuItems={routes}
								user={user}
							/>
						)}
						<HomeLink user={user} />
					</Stack>
					<Box>
						{user.fitnessId ? (
							<LoggedInActions user={user} />
						) : (
							<LoggedOutActions isMobile={Boolean(isMobile)} />
						)}
					</Box>
				</Toolbar>
			</Container>
		</Box>
	);
};

TopBar.propTypes = {
	measurements: PropTypes.object.isRequired,
	user: PropTypes.object,
};

export default TopBar;
