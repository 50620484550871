import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import AFELogo from '../../logos/AFE.svg';
import AFEMobileLogo from '../../logos/AFE-Mobile.svg';
import useIsMobile from '../../utils/customHooks/useIsMobile';

// This will link to the landing page if the user's not logged in or the dashboard if the user is logged in.
const HomeLink = ({ user = null }) => {
	const isMobile = useIsMobile();
	const logoStyle = {
		maxWidth: isMobile ? '80px' : '160px',
		maxHeight: isMobile ? '36px' : '50px',
	};

	return (
		<Box sx={{ minWidth: '100px' }}>
			<Stack direction="row" spacing={2}>
				<Link to={user.fitnessId > 0 ? `/dashboard` : `/`}>
					<img
						src={isMobile ? AFEMobileLogo : AFELogo}
						alt="Active&Fit Logo"
						style={logoStyle}
					/>
				</Link>
				{user.provider && user.provider.logo && (
					<>
						<Divider orientation="vertical" flexItem />
						<img
							src={
								isMobile
									? `assets/logos/${user.provider.logo}-Mobile.svg`
									: `assets/logos/${user.provider.logo}.svg`
							}
							alt="Provider Logo"
							style={logoStyle}
						/>
					</>
				)}
			</Stack>
		</Box>
	);
};
HomeLink.propTypes = {
	user: PropTypes.object,
};

export default HomeLink;
