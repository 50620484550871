import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import useRouteList from '../../utils/customHooks/useRouteList';

const UserProfileDropDown = ({ user }) => {
	const routes = useRouteList();
	const menuItems = routes
		.filter(route => route.includeInProfile)
		.map(route => {
			if (route.children) {
				const includeInProfileSubItems = route.children.filter(
					subItem => subItem.includeInProfile,
				);
				return includeInProfileSubItems;
			}
			return route;
		});
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleOpen}
				aria-label="User profile drop down"
			>
				<PersonSharpIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem
					component={Link}
					onClick={handleClose}
					to="/MyAccount/personal-info"
				>
					<ListItemIcon sx={{ mr: 2 }}>
						<Avatar sx={{ width: 40, height: 40 }} />
					</ListItemIcon>
					<ListItemText>
						{user.firstName} {user.lastName}
						<br />
						Fitness ID: {user.fitnessId}
					</ListItemText>
				</MenuItem>
				<Divider />
				{menuItems.flat().map(item => (
					<MenuItem
						component={Link}
						onClick={handleClose}
						to={item.path}
						key={item.key}
					>
						{item.label}
					</MenuItem>
				))}
				<Divider />
				<MenuItem
					component={Link}
					onClick={() => {
						window.sessionStorage.clear();
						handleClose();
					}}
					to="/logout"
				>
					<ListItemIcon>
						<LogoutIcon />
					</ListItemIcon>
					<ListItemText>Log Out</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};

UserProfileDropDown.propTypes = {
	user: PropTypes.object.isRequired,
};

export default UserProfileDropDown;
