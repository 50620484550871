/* eslint-disable */

/**
 * Contains external code for the Genesys CX chat feature. This is not our code so it isn't
 * being linted or tested.
 * @param {String} contract Client contract ID for the current user
 * @param {String} genesysCXDeploymentId Genesys deployment ID for this website
 * @param {String} genesysCXChatUrl Full URL to an image representing the chat CTA
 * @param {String} genesysCXChatMinimizedUrl Full URL to an image representing an ongoing chat that is minimized
 * @param {Number} memberId The fitness ID of the current user
 * @param {String} subscriptionStatus The subscription status of the current user
 */
export default function genesysChatSetup(
	contract,
	genesysCXDeploymentId,
	genesysCXChatUrl,
	genesysCXChatMinimizedUrl,
	memberId,
	subscriptionStatus,
) {
	// Skip this code during tests
	if (process.env.NODE_ENV === 'test') return;
	// If chat has already been loaded then don't continue
	if (window.Genesys) return;

	// This is a special Genesys status that is a combination of various things
	const status = subscriptionStatus
		? subscriptionStatus
		: contract
			? 'Eligible'
			: 'NotEligible';

	// Add the chat button markup. Original example below but note they are using `image` which is deprecated...
	// <image type="button" id="custom-launcher" onclick="toggleMessenger()" style="position: absolute;bottom:20px;right:20px"></image>
	const chatButton = document.createElement('img');
	chatButton.id = 'custom-launcher';
	chatButton.style = 'position: fixed; bottom: 20px; right: 20px;';
	chatButton.addEventListener('click', toggleMessenger);
	document.body.appendChild(chatButton);

	const beforeChatButton = document.createElement('img');
	beforeChatButton.id = 'before-chatbot-custom-launcher';
	beforeChatButton.style = 'position: fixed; bottom: 80px; right: 0px;';
	beforeChatButton.addEventListener('click', toggleMessenger);
	document.body.appendChild(beforeChatButton);

	// BEGIN Genesys code
	(function (g, e, n, es, ys) {
		g['_genesysJs'] = e;
		g[e] =
			g[e] ||
			function () {
				(g[e].q = g[e].q || []).push(arguments);
			};
		g[e].t = 1 * new Date();
		g[e].c = es;
		ys = document.createElement('script');
		ys.async = 1;
		ys.src = n;
		ys.charset = 'utf-8';
		document.head.appendChild(ys);
	})(
		window,
		'Genesys',
		'https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js',
		{
			environment: 'prod-usw2',
			deploymentId: genesysCXDeploymentId,
		},
	);

	function toggleMessenger() {
		Genesys(
			'command',
			'Messenger.open',
			{},
			function (o) {},
			function (o) {
				// if rejected
				Genesys('command', 'Messenger.close');
			},
		);
	}

	Genesys('subscribe', 'Messenger.opened', function () {
		window.sessionStorage.setItem('agent_button', 'true');
		document.getElementById('custom-launcher').style.display = 'None';
		document.getElementById('before-chatbot-custom-launcher').style.display =
			'None';
	});

	Genesys('subscribe', 'Messenger.closed', function () {
		document
			.getElementById('custom-launcher')
			.setAttribute('src', genesysCXChatMinimizedUrl);
		document.getElementById('custom-launcher').style.display = 'block';
		document.getElementById('before-chatbot-custom-launcher').style.display =
			'None';
	});

	var botButton = document.getElementById('custom-launcher');

	if (window.sessionStorage.getItem('agent_button')) {
		botButton.setAttribute('src', genesysCXChatMinimizedUrl);
	} else {
		var BeforeLauncherButton = document.getElementById(
			'before-chatbot-custom-launcher',
		);
		//replace this with "Chat icon on landing" from  Image mapping Table based on Domain
		BeforeLauncherButton.setAttribute('src', genesysCXChatUrl);
	}

	function setgenesyschatvalue() {
		Genesys('command', 'Database.set', {
			messaging: {
				customAttributes: {
					memberId,
					contractId: contract,
					status,
				},
			},
		});
	}

	Genesys('subscribe', 'MessagingService.ready', function () {
		Genesys(
			'subscribe',
			'MessagingService.conversationReset',
			setgenesyschatvalue,
		);
	});

	setgenesyschatvalue();
	// END Genesys code
}
