import { windowLocation } from './windowLocation';

const redirectUnknownRoute = routeUrl => {
	if (!routeUrl) return null;
	const { pathname } = new URL(routeUrl) || {};

	// prevent infinite loop on homepage
	if (window.location.pathname !== pathname) {
		windowLocation(pathname);
	}
	// Returning an unresolved promise prevents react-router from transitioning to
	// an empty component and keeps us on our current one.
	return new Promise(() => null);
};

export default redirectUnknownRoute;
