import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useQuery } from 'urql';
import PropTypes from 'prop-types';
import useIsMobile from '../../utils/customHooks/useIsMobile';
import TopBar from './TopBar';
import BottomBar from './BottomBar';
import useRouteList from '../../utils/customHooks/useRouteList';
import UserQuery from '../../queries/user';

// Adjustable heights in px for header components
const measurements = {
	bottomBarHeight: 72,
	topBarHeight: 80,
	topBarHeightMobile: 64,
};

const Header = ({ children }) => {
	const routes = useRouteList();
	const hideMenuRoutes = routes.filter(r => !r.showHeaderMenu).map(r => r.path);
	const { zIndex } = useTheme();
	const isMobile = useIsMobile();
	const location = useLocation();
	const [result] = useQuery({
		query: UserQuery,
		variables: { id: 0, tenant: 'activeandfit' },
	});
	const { data } = result;
	const fitnessMember = data?.fitnessMember;

	// Conditions under which we should show the header navigation menu
	const showMenu =
		!isMobile &&
		(fitnessMember?.fitnessId || !hideMenuRoutes.includes(location.pathname));
	return (
		<Box>
			<AppBar
				color="inherit"
				elevation={0}
				position="sticky"
				sx={{
					// Make the header still shown when the drawer is open
					zIndex: zIndex.drawer + 1,
				}}
			>
				<TopBar measurements={measurements} user={fitnessMember} />
				{showMenu && (
					<BottomBar measurements={measurements} user={fitnessMember} />
				)}
			</AppBar>
			<Box>
				<div role="region" aria-label="Page content">
					{children}
				</div>
			</Box>
		</Box>
	);
};

Header.propTypes = {
	children: PropTypes.object.isRequired,
};

export default Header;
