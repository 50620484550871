import PropTypes from 'prop-types';
import genesysChatSetup from '../external/genesysChat';
import OldChatBot from '../external/ChatBot';

/**
 * Handles showing the chat function if it is appropriate to do so.
 */
const ChatBot = ({ user = null }) => {
	if (!user) return null;

	// eslint-disable-next-line prefer-destructuring
	const hostProperties = user.hostProperties;
	const chatBotEnabled = hostProperties?.find(
		h => h.key === 'chatBotEnabled',
	).value;
	const chatBotGenesysEnabled = hostProperties?.find(
		h => h.key === 'chatBotGenesysEnabled',
	).value;

	if (!(chatBotEnabled === 'true')) {
		return null;
	}
	if (chatBotGenesysEnabled === 'true') {
		const chatBotGenesysCXDeploymentId = hostProperties?.find(
			h => h.key === 'chatBotGenesysCXDeploymentId',
		).value;
		const chatBotGenesysCXChatUrl = hostProperties?.find(
			h => h.key === 'chatBotGenesysCXChatUrl',
		).value;
		const chatBotGenesysCXChatMinimizedUrl = hostProperties?.find(
			h => h.key === 'chatBotGenesysCXChatMinimizedUrl',
		).value;
		genesysChatSetup(
			user.clientDetails.contract,
			chatBotGenesysCXDeploymentId,
			chatBotGenesysCXChatUrl,
			chatBotGenesysCXChatMinimizedUrl,
			user.fitnessId,
			user.subscriptionStatus,
		);
		// This is an invisible element that just lets us test that we have decided to show the chat UI;
		// the Genesys script will create the actual markup
		return <span data-testid="genesys-chat-ui" />;
	}
	// TODO Remove after migration complete - show the old chatbot
	return <OldChatBot user={user} />;
};

ChatBot.propTypes = {
	user: PropTypes.object,
};

export default ChatBot;
