export const copyright =
	'© 2018-2023 American Specialty Health Incorporated (ASH). All rights reserved.';
export const copyrightSubText = '';

export const languageAssistance = [
	{
		language: 'English',
		// empty string since additional text for screen readers is not needed since it is english
		srText: '',
	},
	{
		language: 'Español',
		srText: 'Spanish',
	},
	{
		language: '繁體中文',
		srText: 'Chinese',
	},
	{
		language: 'Tagalog',
		srText: '',
	},
	{
		language: 'Tiếng Việt',
		srText: 'Vietnamese',
	},
	{
		language: 'العربية',
		srText: 'Arabic',
	},
	{
		language: 'Français',
		srText: 'French',
	},
	{
		language: '한국어',
		srText: 'Korean',
	},
	{
		language: 'Русский',
		srText: 'Russian',
	},
	{
		language: 'Deutsch',
		srText: 'German',
	},
	{
		language: 'Kreyòl Ayisyen',
		srText: 'French Creole',
	},
	{
		language: 'हिंदी',
		srText: 'Hindi',
	},
	{
		language: 'Português',
		srText: 'Portuguese',
	},
	{
		language: 'Italiano',
		srText: 'Italian',
	},
	{
		language: 'Polski',
		srText: 'Polish',
	},
	{
		language: 'اُردُو',
		srText: 'Farsi',
	},
	{
		language: 'אידיש',
		srText: 'Yiddish',
	},
	{
		language: 'বাংলা',
		srText: 'Bengali',
	},
	{
		language: 'Ελληνικά',
		srText: 'Greek',
	},
	{
		language: 'Shqiptar',
		srText: 'Albanian',
	},
];
