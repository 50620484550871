import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useQuery } from 'urql';
import { useNavigate } from 'react-router-dom';
import { string } from 'prop-types';
import UserQuery from '../queries/user';

const ErrorPage = ({ errorMessage }) => {
	const navigate = useNavigate();

	const [result] = useQuery({
		query: UserQuery,
		variables: { id: 0, tenant: 'activeandfit' },
	});
	const { data } = result;
	const fitnessMember = data?.fitnessMember;

	return (
		<Stack
			mt={17}
			mx={{ xs: 3, sm: 10, md: 20, lg: 30 }}
			spacing={6}
			alignItems="center"
		>
			<Typography
				textAlign="center"
				color="primary"
				variant="h5"
				component="h1"
			>
				{errorMessage}
			</Typography>
			<Button
				startIcon={<ArrowBackIcon />}
				variant="contained"
				onClick={() =>
					fitnessMember?.fitnessId > 0 ? navigate(`/dashboard`) : navigate(`/`)
				}
				color="secondary"
			>
				RETURN HOME
			</Button>
			<Divider sx={{ width: '70%' }} />
			<Typography variant="body1">
				Please{' '}
				<Link
					href="/contactus"
					sx={{ textDecoration: 'none' }}
					color="secondary"
				>
					Contact Us
				</Link>{' '}
				if you have any questions.
			</Typography>
		</Stack>
	);
};

ErrorPage.propTypes = {
	/**
	 * Message that will be displayed to the user.
	 */
	errorMessage: string.isRequired,
};

export default ErrorPage;
