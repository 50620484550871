import { useQuery } from 'urql';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { visuallyHidden } from '@mui/utils';
import ASHLogo from '../../logos/ASHLogo.svg';
import igLogo from '../../logos/igLogo.svg';
import fbLogo from '../../logos/fbLogo.svg';
import twitterLogo from '../../logos/twitterLogo.svg';
import linkedInLogo from '../../logos/linkedInLogo.svg';
import { languageAssistance, copyright, copyrightSubText } from './data';
import useRouteList from '../../utils/customHooks/useRouteList';
import UserQuery from '../../queries/user';
import ChatBot from '../chatBot/ChatBot';

const FooterMenu = () => {
	const routes = useRouteList();
	const menuItems = routes.filter(r => r.includeInFooter);
	return (
		<Grid container direction="row" mt={8} spacing={3.5}>
			{menuItems.map(menu => (
				<Grid
					key={menu.path}
					size={{
						xs: 6,
						sm: 6,
						lg: 3,
					}}
				>
					<Link
						color="inherit"
						component={Link}
						href={menu.path}
						underline="hover"
					>
						<Typography variant="body2">{menu.label}</Typography>
					</Link>
				</Grid>
			))}
		</Grid>
	);
};

export default function Footer() {
	const [result] = useQuery({
		query: UserQuery,
		variables: { id: 0, tenant: 'activeandfit' },
	});
	const { data, fetching } = result;
	const fitnessMember = data?.fitnessMember;

	return (
		<Box
			component="footer"
			role="contentinfo"
			sx={{
				backgroundColor: 'grey.50',
				color: 'text.primary',
				p: 0,
			}}
		>
			<Box>
				<Container>
					<Grid container mb={6}>
						<Grid
							size={{
								xs: 12,
								sm: 12,
								md: 4,
								lg: 3.5,
							}}
						>
							<Stack>
								<Typography mt={7} mb={1} variant="overline" component="p">
									A PRODUCT OF
								</Typography>
								<Box sx={{ width: '15rem', height: '2rem' }} mb={2}>
									<Link
										href="https://www.ashcompanies.com/"
										underline="none"
										target="_blank"
										rel="noopener"
									>
										<img src={ASHLogo} alt="ASH Logo" />
									</Link>
								</Box>
								<Stack direction="row">
									<Box sx={{ width: '7px', height: '14px' }} mr={3} ml={1}>
										<Link
											href="https://www.facebook.com/MyActiveandFit/"
											target="_blank"
											rel="noopener"
											data-test-ash-link-id="facebook"
										>
											<img src={fbLogo} alt="Facebook Logo" />
										</Link>
									</Box>
									<Box sx={{ width: '18px', height: '14px' }} mr={3}>
										<Link
											href="https://twitter.com/myactiveandfit"
											target="_blank"
											rel="noopener"
											data-test-ash-link-id="twitter"
										>
											<img src={twitterLogo} alt="Twitter Logo" />
										</Link>
									</Box>
									<Box sx={{ width: '1 rem', height: '1 rem' }} mr={3}>
										<Link
											href="https://www.linkedin.com/company/american-specialty-health/"
											target="_blank"
											rel="noopener"
											data-test-ash-link-id="linkedin"
										>
											<img src={linkedInLogo} alt="LinkedIn Logo" />
										</Link>
									</Box>
									<Box sx={{ width: '1 rem', height: '1 rem' }} mr={3}>
										<Link
											href="https://www.instagram.com/getactiveandfit/"
											target="_blank"
											rel="noopener"
											data-test-ash-link-id="instagram"
										>
											<img src={igLogo} alt="Instagram Logo" />
										</Link>
									</Box>
								</Stack>
							</Stack>
						</Grid>
						<Grid
							display="flex"
							flexWrap="wrap"
							size={{
								md: 8,
							}}
						>
							<FooterMenu />
						</Grid>
					</Grid>
					<Divider
						sx={{ borderColor: 'grey', margin: '2.5rem 0' }}
						orientation="horizontal"
					/>
					<Typography variant="body2" mb={3}>
						<OpenInNewIcon
							sx={{ fontSize: 16, top: '0.1rem', position: 'relative' }}
						/>{' '}
						Clicking on these links will take you away from Activeandfit.com.
						Active&amp;Fit Enterprise is not responsible for any content or the
						privacy practices of external websites.
					</Typography>
					<Typography variant="body2" mt={3} mb={3}>
						The Active&amp;Fit Enterprise program is a product of American
						Specialty Health Fitness, Inc. (ASH Fitness), a subsidiary of
						American Specialty Health Incorporated. ASH Fitness complies with
						applicable Federal civil rights laws and does not discriminate on
						the basis of race, color, national origin, age, disability, or sex.
						Active&amp;Fit Enterprise and the Active&amp;Fit Enterprise logo are
						trademarks of ASH. Other names and logos may be trademarks of their
						respective owners.
					</Typography>
					<Typography variant="body2" gutterBottom>
						For language services, please call the number on your member ID card
						and request an operator. For other language services:
					</Typography>

					<Grid container direction="row" mb={6} ml={-2}>
						{fetching
							? languageAssistance.map(lang => (
									<Grid key={lang.language} pl={2} pr={1} mb={2}>
										<Skeleton width="3rem" />
									</Grid>
								))
							: languageAssistance.map((lang, i, { length }) => (
									<Grid key={lang.language}>
										<Stack direction="row" mb={2}>
											<Typography variant="body2" component="div">
												<Link
													href={
														fitnessMember?.footerConfig?.languageAssistancePath
													}
													underline="hover"
													target="_blank"
													rel="noopener"
													color="secondary.main"
													paddingLeft={2}
													paddingRight={1}
												>
													{lang.language}
													<Box sx={visuallyHidden}>{lang.srText}</Box>
												</Link>
											</Typography>
											{length - 1 === i ? null : (
												<Divider
													orientation="vertical"
													flexItem
													sx={{ bgcolor: 'text.primary' }}
												/>
											)}
										</Stack>
									</Grid>
								))}
					</Grid>
				</Container>
			</Box>
			<Box sx={{ backgroundColor: 'primary.main' }} pt={4} pb={4} pl={2} pr={2}>
				<Container display="flex">
					<Stack
						spacing={2}
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent="space-between"
					>
						<Typography
							fontSize="14px"
							color="white"
							textAlign={{ xs: 'center' }}
						>
							{copyright}
						</Typography>
						<Typography
							fontSize="14px"
							color="white"
							textAlign={{ xs: 'center' }}
						>
							{copyrightSubText}
						</Typography>
					</Stack>
				</Container>
			</Box>
			<ChatBot user={fitnessMember} />
		</Box>
	);
}
