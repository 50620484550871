import { gql } from 'urql';

const UserQuery = gql`
	query fitnessMember($id: Int!, $tenant: String!) {
		fitnessMember(id: $id, tenant: $tenant) {
			claims {
				key
				value
			}
			clientDetails {
				clientName
				cobrandLogoUrl
				contract
				customizationImageRaw
				customizationImageRawMobile
				imgLogoAlign
			}
			hostProperties {
				key
				value
			}
			footerConfig {
				copyright
				languageAssistancePath
			}
			firstName
			fitnessId
			groupId
			lastName
			subscriptionStatus
			sessionBlob
			siteWarning
		}
	}
`;

export default UserQuery;
