import PropTypes from 'prop-types';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import jump from 'jump.js';
import { useTheme } from '@mui/material/styles';
import { NavLink, useMatch, useNavigate } from 'react-router-dom';
import useRouteList from '../../utils/customHooks/useRouteList';

const MenuButton = ({ height, label, path }) => {
	const { palette } = useTheme();
	const activeLinkStyles = {
		borderRadius: 0,
		borderBottom: `2px solid ${palette.secondary.main}`,
		color: 'secondary.main',
	};
	const defaultLinkStyles = {
		'&:hover': {
			...activeLinkStyles,
		},
		color: 'text.primary',
		fontWeight: 'bold',
		height,
		px: { md: 3 },
	};
	const isActive = useMatch(path);

	if (path.startsWith('#')) {
		// This is an in-page link
		return (
			<Button
				component={Link}
				sx={defaultLinkStyles}
				onClick={() =>
					jump(path, {
						offset: -Number(document.querySelector('header')?.offsetHeight),
					})
				}
			>
				{label}
			</Button>
		);
	}
	// Return a routable button
	return (
		<Button
			component={NavLink}
			sx={
				isActive
					? { ...defaultLinkStyles, ...activeLinkStyles }
					: defaultLinkStyles
			}
			to={path}
		>
			{label}
		</Button>
	);
};
MenuButton.propTypes = {
	label: PropTypes.string.isRequired,
	height: PropTypes.number.isRequired,
	path: PropTypes.string.isRequired,
};

const BottomBar = ({ measurements, user = null }) => {
	const routes = useRouteList();
	const menuItems = routes.filter(r => r.includeInHeader);
	const [menuAnchors, menuAnchorsSet] = useState({});
	const menuSet = (key, element) => {
		menuAnchorsSet(state => {
			const newState = { ...state };
			newState[key] = element;
			return newState;
		});
	};
	const navigate = useNavigate();
	return (
		menuItems?.length && (
			<Box sx={{ bgcolor: 'background.eveCustom.secondary', mt: '2px' }}>
				<Container role="navigation">
					<Toolbar
						disableGutters
						sx={{
							px: '0px !important',
							minHeight: `${measurements.bottomBarHeight}px !important`,
							bgcolor: 'background.eveCustom.secondary',
							flexWrap: 'wrap',
						}}
					>
						{menuItems
							// Only show protected menu items if the user is logged in
							.filter(({ isProtected, inclueInLoggedInHeader }) =>
								user?.fitnessId
									? isProtected || inclueInLoggedInHeader
									: !isProtected,
							)
							// Check the claim for each page
							.filter(
								({ claim }) =>
									!claim ||
									user?.claims.find(c => c.key === claim && c.value === true),
							)
							.map(({ anchor, label, key, path, children }) => {
								if (children) {
									return (
										<div key={key}>
											<Button
												aria-haspopup="true"
												aria-expanded={menuAnchors[key] ? 'true' : undefined}
												aria-controls={
													menuAnchors[key] ? `${key}-menu` : undefined
												}
												color="inherit"
												id={`${key}-menuButton`}
												onClick={event => menuSet(key, event.currentTarget)}
												sx={{ fontWeight: 'bold' }}
											>
												{label.toUpperCase()}
												<KeyboardArrowDownIcon />
											</Button>
											<Menu
												id={`${key}-menu`}
												anchorEl={menuAnchors[key]}
												onClose={() => menuSet(key, null)}
												open={Boolean(menuAnchors[key])}
												MenuListProps={{
													'aria-labelledby': `${key}-menuButton`,
												}}
											>
												{children
													.filter(
														({ claim }) =>
															!claim ||
															user?.claims.find(
																c => c.key === claim && c.value === true,
															),
													)
													.map(
														s =>
															s.includeInHeader && (
																<MenuItem
																	key={`${s.key}-menu`}
																	onClick={() => {
																		menuSet(key, null);
																		navigate(s.path);
																	}}
																	sx={{ fontWeight: 'bold' }}
																>
																	{s.label}
																</MenuItem>
															),
													)}
											</Menu>
										</div>
									);
								}
								return (
									<MenuButton
										key={`${key}-menu-button`}
										label={label}
										height={measurements.bottomBarHeight}
										path={anchor || path}
									/>
								);
							})}
					</Toolbar>
				</Container>
			</Box>
		)
	);
};

BottomBar.propTypes = {
	measurements: PropTypes.object.isRequired,
	user: PropTypes.object,
};

export default BottomBar;
