import {
	QueryClientProvider as ReactQueryProvider,
	QueryClient,
} from 'react-query';
import { element, oneOfType, arrayOf } from 'prop-types';

// This component sets up react-query
const QueryClientProvider = ({ children = null }) => {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				suspense: true,
			},
		},
	});

	return (
		<ReactQueryProvider client={queryClient}>{children}</ReactQueryProvider>
	);
};

QueryClientProvider.propTypes = {
	children: oneOfType([element, arrayOf(element)]),
};


export default QueryClientProvider;
