import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import jump from 'jump.js';
import useRouteList from '../../utils/customHooks/useRouteList';

const MenuButton = ({ label, onClick, path, ...args }) => {
	const navigate = useNavigate();
	if (path.startsWith('#')) {
		return (
			<ListItemButton
				component={Link}
				key={`#-${label}`}
				onClick={() => {
					onClick();
					jump(path, {
						offset: -Number(document.querySelector('header')?.offsetHeight),
					});
				}}
				{...args}
			>
				<ListItemText primary={label} />
			</ListItemButton>
		);
	}

	return (
		<ListItemButton
			key={`button-${label}`}
			component={Button}
			onClick={() => {
				onClick();
				navigate(path);
			}}
			{...args}
		>
			<ListItemText primary={label} />
		</ListItemButton>
	);
};

MenuButton.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	path: PropTypes.string.isRequired,
};

const MobileNavMenu = ({ measurements, user = null }) => {
	const routes = useRouteList();
	const menuItems = routes.filter(r => r.includeInHeader);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [menuAnchors, menuAnchorsSet] = useState({});

	const menuSet = (key, element) => {
		menuAnchorsSet(state => {
			const newState = { ...state };
			newState[key] = newState[key] ? null : element;
			return newState;
		});
	};

	return (
		<>
			<IconButton
				color="inherit"
				aria-label="Mobile menu"
				aria-haspopup="true"
				onClick={() => setDrawerOpen(prev => !prev)}
			>
				{drawerOpen ? <CloseRoundedIcon /> : <MenuIcon />}
			</IconButton>
			<Drawer
				anchor="left"
				onClose={() => setDrawerOpen(false)}
				open={drawerOpen}
				role="navigation"
			>
				<List
					sx={{
						pt: {
							xs: `${measurements.topBarHeightMobile + 25}px`,
							sm: `${measurements.topBarHeight + 25}px`,
						},
					}}
				>
					{menuItems
						.filter(({ isProtected }) =>
							user?.fitnessId ? isProtected : !isProtected,
						)
						.filter(
							({ claim }) =>
								!claim ||
								user?.claims.find(c => c.key === claim && c.value === true),
						)
						.map(({ anchor, label, key, path, children }) => {
							if (children) {
								return (
									<ListItem key={`parent-${key}`}>
										<Stack direction="column">
											<ListItemButton
												onClick={event => menuSet(key, event.currentTarget)}
											>
												<ListItemText primary={label} />
												{menuAnchors[key] ? (
													<KeyboardArrowDownOutlinedIcon />
												) : (
													<KeyboardArrowRightIcon />
												)}
											</ListItemButton>
											<Collapse
												in={Boolean(menuAnchors[key])}
												timeout="auto"
												unmountOnExit
											>
												<List component="div" disablePadding>
													{children
														.filter(
															({ claim }) =>
																!claim ||
																user?.claims.find(
																	c => c.key === claim && c.value === true,
																),
														)
														.map(
															subItem =>
																subItem.includeInHeader && (
																	<ListItem key={`sub-${subItem.key}`}>
																		<MenuButton
																			label={subItem.label}
																			onClick={() => setDrawerOpen(false)}
																			path={subItem.path}
																			sx={{ pl: 4 }}
																		/>
																	</ListItem>
																),
														)}
												</List>
											</Collapse>
										</Stack>
									</ListItem>
								);
							}
							return (
								<ListItem key={key}>
									<MenuButton
										label={label}
										onClick={() => setDrawerOpen(false)}
										path={anchor || path}
									/>
								</ListItem>
							);
						})}
				</List>
			</Drawer>
		</>
	);
};

MobileNavMenu.propTypes = {
	measurements: PropTypes.object.isRequired,
	user: PropTypes.object,
};

export default MobileNavMenu;
