import { LicenseInfo } from '@mui/x-license';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'urql';
import EveThemeProvider from '@ashn/eve-themes-react/EveThemeProvider';

import reportWebVitals from './reportWebVitals';
import { createUrqlClient, maybeStartMocks } from './indexSupport';
import App from './App';
import QueryClientProvider from './data/ReactQueryProvider';

maybeStartMocks();

LicenseInfo.setLicenseKey(
	'948975b5e0d79ec68b5aae098ae1be9cTz05MTM5NyxFPTE3NDg0MzIyMzIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<QueryClientProvider>
			<Provider value={createUrqlClient()}>
				<EveThemeProvider themeKey="afb" themeMode="light">
					<HelmetProvider>
						<App />
					</HelmetProvider>
				</EveThemeProvider>
			</Provider>
		</QueryClientProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
