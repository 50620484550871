import { Outlet } from 'react-router-dom';
import PageContainer from '../../components/shared/PageContainer/PageContainer';
import PageSEO from '../../components/shared/PageSEO';
import ProtectedPage from '../../components/routing/ProtectedPage';

const CheckIns = () => (
	<ProtectedPage>
		<PageContainer>
			<PageSEO pageTitle="Check Ins" />
			<Outlet />
		</PageContainer>
	</ProtectedPage>
);

export default CheckIns;
