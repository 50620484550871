import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/*
# To-do

- Decide about tag casing
- Add some consistency checks
- Write tests

# Config updates

jest.config.js
	moduleNameMapper: {
		'\\.(jpg|ico|jpeg|png|gif|eot|otf|webp|svg|ttf|woff|woff2|mp4|webm|wav|mp3|m4a|aac|oga|avif)$':
			'<rootDir>/__mocks__/fileMock.js',
		'\\.(css|less)$': '<rootDir>/__mocks__/fileMock.js',
	},

.storybook/main.js
	webpackFinal: async (config, { configType }) => {
		// `configType` has a value of 'DEVELOPMENT' or 'PRODUCTION'
		// You can change the configuration based on that.
		// 'PRODUCTION' is used when building the static version of storybook.
		const isProd = configType === 'PRODUCTION';

		// Add support for the AVIF file format
		config.module.rules.push({
			test: /\.(avif)(\?.*)?$/,
			type: 'asset/resource',
			generator: {
				filename: isProd
					? 'static/media/[name].[contenthash:8][ext]'
					: 'static/media/[path][name][ext]',
			},
		});

		// Return the altered config
		return config;
	},

# Name replace

gci|%{rni $_.fullname  -NewName ($_.fullname -replace '-(\dx)','.$1') } 
gci|%{rni $_.fullname  -NewName ($_.fullname -replace '_desktop\.','.lg.') }
gci|%{rni $_.fullname  -NewName ($_.fullname -replace '_tablet\.','.md.') }
gci|%{rni $_.fullname  -NewName ($_.fullname -replace '_mobile\.','.xs.') }

# POSH import helper

<#
.SYNOPSIS
  Given a fragment of the image name, output the import statements and array of names for use in a React project.
.EXAMPLE
	≡][≡ Get-ImageImports.ps1 connected
	import connectedbgmd2xavif from './connected_bg.md.2x.avif'
	import connectedbgmd2xwebp from './connected_bg.md.2x.webp'
	...
	import connectedbgsm3xavif from './connected_bg.sm.3x.avif'
	import connectedbgsm3xwebp from './connected_bg.sm.3x.webp'
	[connectedbgmd2xavif,connectedbgmd2xwebp,connectedbgmd3xavif,connectedbgmd3xwebp,connectedbgmdavif,connectedbgmdwebp,connectedbgsm2xavif,connectedbgsm2xwebp,connectedbgsm3xavif,connectedbgsm3xwebp,]
.PARAMETER Basename
  Part of the base name of the files - it will be surrounded with wildcards so you don't need the entire name
#>
param([String]$Basename = (Get-Item -Path ".\").Name)

Get-ChildItem *$Basename* | ForEach-Object { 
	"import $($_.name.replace('.','').replace('_', '')) from './$($_.name)'" 
}

Get-ChildItem *$Basename* | ForEach-Object { 
	"$($_.name.replace('.','').replace('_', ''))," 
} | join-string -OutputPrefix '[' -OutputSuffix ']'

*/

// These are the formats, densities, and breakpoints we support. They are in a specific order of
// least to most 'desired' or specific.
const pixelDensities = ['1x', '2x', '3x'];
const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
const formats = ['webp', 'avif'];

/**
 * This hook was taken from MUI: https://mui.com/material-ui/react-use-media-query/#migrating-from-withwidth
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 * @function useWidth
 * @return {String}
 */
export function useWidth() {
	const theme = useTheme();
	const keys = [...theme.breakpoints.keys].reverse();
	return (
		keys.reduce((output, key) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const matches = useMediaQuery(theme.breakpoints.up(key));
			return !output && matches ? key : output;
		}, null) || 'xs'
	);
}

/**
 * Resolves to true or false based on whether the AVIF format is supported in the current
 * browser. The value is cached on the `window` object since it should never be able to
 * change.
 * @function isAvifSupported
 * @return {Promise}
 */
export async function isAvifSupported() {
	if (window.isAvifSupportedState === undefined) {
		const image = new Image();
		image.src =
			'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=';
		try {
			await image.decode();
			window.isAvifSupportedState = true;
		} catch (err) {
			window.isAvifSupportedState = false;
		}
	}
	return window.isAvifSupportedState;
}

/**
 * Given an array of image paths, determine which breakpoints, formats, and
 * pixel densities are present. The resulting array is sorted in order of 'best'
 * to 'worst' image based on specificity and file size.
 * @function analyzeImages
 * @param {Array} images An array of image paths
 * @return {Array}
 */
function analyzeImages(images = []) {
	const hasTag = (imagePath, tag) =>
		imagePath.includes(`.${tag}.`) || imagePath.endsWith(`.${tag}`);
	return images
		.map(i => {
			const density = pixelDensities.find(d => hasTag(i, d));
			const format = formats.find(f => hasTag(i, f));
			const breakpoint = breakpoints.find(b => hasTag(i, b));
			const densityIndex = pixelDensities.indexOf(density);
			const formatIndex = formats.indexOf(format);
			const breakpointIndex = breakpoints.indexOf(breakpoint);
			return {
				path: i,
				density,
				densityIndex,
				format,
				formatIndex,
				breakpoint,
				breakpointIndex,
				score: densityIndex + formatIndex + breakpointIndex,
			};
		})
		.sort((a, b) => b.score - a.score);
}

/**
 * Split up images by file format for creating source elements. The result is reversed
 * since we want browsers to take the best format they can utilize.
 * @function filterByFormat
 * @param {Array} analyzed An array of images that have already been analyzed and tagged
 * @return {Array}
 */
function filterByFormat(analyzed) {
	return formats
		.map(f => ({
			format: f,
			images: analyzed.filter(i => i.format === f),
		}))
		.filter(f => f.images.length)
		.reverse();
}

/**
 * Split up images by breakpoint for creating source elements. The result is reversed
 * since we want browsers to take the breakpoint they can utilize.
 * @function filterByBreakpoint
 * @param {Array} analyzed An array of images that have already been analyzed and tagged
 * @return {Array}
 */
function filterByBreakpoint(analyzed) {
	return breakpoints
		.map(b => ({
			breakpoint: b,
			images: analyzed.filter(i => i.breakpoint === b),
		}))
		.filter(b => b.images.length)
		.reverse();
}

/**
 * Returns a string for use in the media attribute on a source element.
 * @function createMediaAttr
 * @param {Object} theme An MUI theme
 * @param {String} muiBreakpoint The MUI breakpoint to use as reference
 * @return {String}
 */
function createMediaAttr(theme, muiBreakpoint) {
	return theme.breakpoints.up(muiBreakpoint).replace('@media ', '');
}

/**
 * Returns a string for assignment to a srcSet property.
 * @function createSrcsetAttr
 * @param {Array} analyzed An array of images that have already been analyzed and tagged
 * @return {String}
 */
function createSrcsetAttr(analyzed) {
	return analyzed
		.map(i => (i.density ? `${i.path} ${i.density}` : i.path))
		.join(',');
}

/**
 * Given analyzed images, return the 'worst' image path based on scoring the tags in order
 * of specificity.
 * @function chooseFallbackImage
 * @param {Array} analyzed An array of images that have already been analyzed and tagged
 * @return {String}
 */
function chooseFallbackImage(analyzed) {
	// console.log('sorted images', sorted);
	return analyzed[analyzed.length - 1]?.path || '';
}

/**
 * Given an array of images, return the best one for the current browser and display
 * capabilities. Since AVIF support is async, this hook will return an empty string
 * until AVIF support is determined.
 * @function useBestImage
 * @param {Array} images An array of image paths
 * @return {String}
 */
export function useBestImage(images) {
	const [avifSupported, avifSupportedSet] = useState();
	const [bestImage, bestImageSet] = useState('');
	const muiWidth = useWidth();

	// Only check for AVIF support if we don't already know it, otherwise
	// this will always trigger the useEffect and cascading renders
	if (avifSupported === undefined)
		isAvifSupported().then(isSupported => avifSupportedSet(isSupported));
	const analyzed = analyzeImages(images);
	useEffect(() => {
		// If we don't yet know AVIF support, don't attempt to return an image
		if (avifSupported === undefined) return;
		const densityIndex = pixelDensities.indexOf(`${window.devicePixelRatio}x`);
		const formatIndex = avifSupported ? 1 : 0;
		const breakpointIndex = breakpoints.indexOf(muiWidth);
		const calculatedImage = analyzed.find(
			i =>
				i.densityIndex <= densityIndex &&
				i.formatIndex <= formatIndex &&
				i.breakpointIndex <= breakpointIndex,
		);
		bestImageSet(
			calculatedImage ? calculatedImage?.path : chooseFallbackImage(analyzed),
		);
	}, [muiWidth, avifSupported]);
	return bestImage;
}

/**
 * Given an array of image filenames, chooses the most appropriate image to
 * set as a background given the current user's browser.
 */
export function BoxBackground({ children = null, images, sx = {}, ...args }) {
	const bestImage = useBestImage(images);

	return (
		<Box
			{...args}
			sx={{
				backgroundImage: `url(${bestImage})`,
				...sx,
			}}
		>
			{children}
		</Box>
	);
}
BoxBackground.propTypes = {
	children: PropTypes.object,
	images: PropTypes.array.isRequired,
	sx: PropTypes.object,
};

export function PictureHelper({ images, alt, ...args }) {
	const theme = useTheme();
	const analyzed = analyzeImages(images);
	const formatMimeType = {
		webp: 'image/webp',
		avif: 'image/avif',
	};
	// Split the image list by type
	const analyzedByFormat = filterByFormat(analyzed);
	const fallbackImage = chooseFallbackImage(analyzed);

	if (!images.length) return null;
	return (
		<picture>
			{analyzedByFormat.map(f =>
				filterByBreakpoint(f.images).map(b => (
					<source
						key={`${f.format}-${b.breakpoint}`}
						srcSet={createSrcsetAttr(b.images)}
						media={createMediaAttr(theme, b.breakpoint)}
						type={formatMimeType[f.format]}
					/>
				)),
			)}
			{fallbackImage && <img {...args} src={fallbackImage} alt={alt} />}
		</picture>
	);
}
PictureHelper.propTypes = {
	images: PropTypes.array.isRequired,
	alt: PropTypes.string.isRequired,
};
