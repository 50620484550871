import { Outlet } from 'react-router-dom';
import redirectUnknownRoute from '../../utils/redirectUnknownRoute';

export const myAccountRoutes = [
	{
		claim: null,
		element: null,
		loader: ({ request: { url } }) => redirectUnknownRoute(url),
		includeInFooter: true,
		includeInHeader: false,
		includeInProfile: true,
		isProtected: true,
		isNonReactPage: true,
		key: 'MyAccount-personal-info',
		label: 'Personal Info',
		path: '/MyAccount/personal-info',
		gaLabel: 'personal_info',
	},
	{
		claim: null,
		element: null,
		loader: ({ request: { url } }) => redirectUnknownRoute(url),
		includeInFooter: true,
		includeInHeader: false,
		includeInProfile: true,
		isProtected: true,
		isNonReactPage: true,
		key: 'MyAccount-identity-profile-security',
		label: 'Password & Security',
		path: '/MyAccount/identity/profile/security',
		gaLabel: 'password_and_security',
	},
	{
		claim: null,
		element: null,
		loader: ({ request: { url } }) => redirectUnknownRoute(url),
		includeInFooter: true,
		includeInHeader: false,
		includeInProfile: true,
		isProtected: true,
		isNonReactPage: true,
		key: 'MyAccount-program-info',
		label: 'Program Info',
		path: '/MyAccount/program-info',
		gaLabel: 'program_info',
	},
	{
		claim: null,
		element: <Outlet />,
		isProtected: true,
		includeInFooter: true,
		includeInHeader: false,
		includeInProfile: true,
		isNonReactPage: false,
		key: 'MyAccount-billing-index',
		label: 'Billing & Payments',
		path: '/MyAccount/billing',
		gaLabel: 'billing_and_payments',
	},
	{
		claim: null,
		element: null,
		loader: ({ request: { url } }) => redirectUnknownRoute(url),
		includeInFooter: true,
		includeInHeader: false,
		includeInProfile: true,
		isProtected: true,
		isNonReactPage: true,
		key: 'MyAccount-manage-memberships',
		label: 'Manage Memberships',
		path: '/MyAccount/manage-memberships',
		gaLabel: 'manage_memberships',
	},
];
