import { useQuery } from 'urql';
import { Helmet } from 'react-helmet-async';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import UserQuery from '../queries/user';

const SiteHead = () => {
	const [result] = useQuery({
		query: UserQuery,
		variables: { id: 0, tenant: 'activeandfit' },
	});
	const { data, fetching } = result;
	const user = data?.fitnessMember;
	if (fetching) return null;
	const googleTagManagerId = user?.hostProperties?.find(
		h => h.key === 'googleTagManagerId',
	).value;

	const googleAnalytics4Id = user?.hostProperties?.find(
		h => h.key === 'googleAnalytics4Id',
	).value;

	return (
		<GTMProvider
			state={{
				id: googleTagManagerId,
			}}
		>
			<Helmet>
				<script
					async
					data-testid="gtmScript"
					type="text/javascript"
					src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalytics4Id}&amp;l=dataLayer&amp;cx=c`}
				/>
				<script data-testid="dataLayerScript" type="text/javascript">
					{`
							window.dataLayer = window.dataLayer || [];
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							gtag('config', '${googleAnalytics4Id}', 
							{
									'linker': { 'domains': ['activeandfit.com','blog.activeandfit.com'] },
									'groupId': '${user.groupId}',
									'subscriptionStatus': '${user.subscriptionStatus}'
								}
							);
						`}
				</script>
			</Helmet>
		</GTMProvider>
	);
};

export default SiteHead;
