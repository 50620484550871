import {
	createBrowserRouter,
	Outlet,
	RouterProvider,
	ScrollRestoration,
} from 'react-router-dom';
import SiteHead from './components/SiteHead';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import useRouteList from './utils/customHooks/useRouteList';

const router = routes =>
	createBrowserRouter([
		{
			path: '/',
			element: (
				<>
					<ScrollRestoration />
					<Header>
						<Outlet />
					</Header>
					<Footer />
				</>
			),
			children: routes,
		},
	]);

function App() {
	const routes = useRouteList();

	return (
		<>
			<SiteHead />
			<RouterProvider router={router(routes)} />
		</>
	);
}

export default App;
