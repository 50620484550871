import { alpha } from '@mui/system/colorManipulator';
import { NavLink, Outlet, useMatches } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { myAccountRoutes } from './myAccountRoutes';
import useIsMobile from '../../utils/customHooks/useIsMobile';

const MenuButton = ({ path, label }) => {
	const { palette } = useTheme();
	// switched to useMatches to be able to highlight billing and payments nav when the user is on the payment detail page
	const isActive =
		useMatches().filter(match => match.pathname.includes(path)).length > 0;

	const activeLinkStyles = {
		backgroundColor: alpha(palette.secondary.main, 0.08),
		color: palette.secondary.main,
	};
	const defaultLinkStyles = {
		'&:hover': {
			...activeLinkStyles,
		},
	};
	return (
		<MenuItem
			component={NavLink}
			to={path}
			sx={
				isActive
					? { ...defaultLinkStyles, ...activeLinkStyles }
					: defaultLinkStyles
			}
		>
			{label}
		</MenuItem>
	);
};
MenuButton.propTypes = {
	path: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
};

const MyAccount = () => {
	const isMobile = useIsMobile();
	return (
		<Container>
			<Box my={4}>
				<Typography component="h1" variant="h4" color="primary" gutterBottom>
					My Account
				</Typography>
				{!isMobile && <Divider sx={{ mt: 2, mb: 4, bgcolor: 'grey.400' }} />}
				<Grid container columnGap={4}>
					{!isMobile && (
						<Grid size={3}>
							<Paper elevation={6} sx={{ minHeight: '335px', py: 1 }}>
								<MenuList>
									{myAccountRoutes.map(item => (
										<MenuButton
											path={item.path}
											label={item.label}
											key={item.key}
										/>
									))}
								</MenuList>
							</Paper>
						</Grid>
					)}
					<Grid
						size={{
							xs: 12,
							md: 8,
						}}
					>
						<Outlet />
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};

export default MyAccount;
