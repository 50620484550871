import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, lighten } from '@mui/material/styles';
import { useEffect, useState } from 'react';

const SiteWarning = ({ message = null, children }) => {
	const theme = useTheme();
	const [warningOpen, setWarningOpen] = useState(false);

	useEffect(() => {
		// Set warningOpen to true if the message is not empty
		setWarningOpen(!!message);
	}, [message]);

	const headerOffset =
		document.getElementsByTagName('header')[0]?.offsetHeight ?? 0;
	const siteWarningOffset =
		document.getElementById('siteWarning')?.offsetHeight ?? 0;

	return (
		<Box
			pt={warningOpen ? `${siteWarningOffset}px` : ''}
			// Matching the transition of the Collapse component so content does not jump
			sx={{ transition: 'padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
		>
			<Collapse
				in={warningOpen}
				easing={{
					enter: theme.transitions.easing.easeIn,
					exit: theme.transitions.easing.easeOut,
				}}
				sx={{
					position: 'fixed',
					top: headerOffset,
					width: '100%',
					zIndex: 600,
					// matching alert background color to make the banner go to the edge of the page
					bgcolor: lighten(theme.palette.info.light, 0.9),
				}}
			>
				<Container>
					<Alert
						id="siteWarning"
						severity="info"
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setWarningOpen(false);
								}}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
					>
						<Typography dangerouslySetInnerHTML={{ __html: message }} />
					</Alert>
				</Container>
			</Collapse>
			{children}
		</Box>
	);
};

export default SiteWarning;

SiteWarning.propTypes = {
	message: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

