import { Helmet } from 'react-helmet-async';
import { string } from 'prop-types';

const PageSEO = ({ pageTitle, description = null }) => (
	<Helmet>
		<title>Active&amp;Fit Enterprise | {pageTitle}</title>
		{description && <meta name="description" content={description} />}
	</Helmet>
);

PageSEO.propTypes = {
	pageTitle: string.isRequired,
	description: string,
};

export default PageSEO;
