import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const PageContainer = ({ children }) => (
	<Container>
		<Box my={4}>{children}</Box>
	</Container>
);

PageContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default PageContainer;
