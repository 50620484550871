const envDefs = {
	dev: 'dev',
	tst: 'tst',
	stg: 'stg',
	www: 'prod',
};

/**
 * This function return the env you are on based on the url
 */
const getEnvironment = () => {
	const hostEnv = window.location.host?.split('.').shift();
	const currEnv = envDefs[hostEnv] || 'dev';

	return currEnv;
};

export default getEnvironment;
