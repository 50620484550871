import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useQuery } from 'urql';
import { windowLocation } from '../../utils/windowLocation';
import UserQuery from '../../queries/user';

const ProtectedPage = ({ children }) => {
	const [result] = useQuery({
		query: UserQuery,
		variables: { id: 0, tenant: 'activeandfit' },
	});
	const { data } = result;
	const fitnessMember = data?.fitnessMember;

	if (fitnessMember) {
		const isLoggedIn = fitnessMember.fitnessId > 0;
		if (!isLoggedIn) {
			const encodedUrl = encodeURIComponent(window.location.href);
			window.sessionStorage.clear();
			windowLocation(`/identity/login?redirect=${encodedUrl}`);
		} else {
			return children;
		}
	}
	return (
		<Stack>
			<Typography variant="h1">
				<Skeleton />
			</Typography>
			<Skeleton variant="rectangle" height={500} />
		</Stack>
	);
};

ProtectedPage.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ProtectedPage;
