/* eslint-disable */
import PropTypes from 'prop-types';

/**
 * Encapsulates the chatbot code. This is not written by us, so we are not
 * testing it or considering it for code coverage.
 */
const ChatBot = ({ user }) => {
	// Skip this code during tests because it will fail
	if (process.env.NODE_ENV === 'test')
		return <span data-testid="bold360-chat-ui" />;
	const getHostProp = key =>
		user.hostProperties?.find(p => p.key === key)?.value;
	const chatBotBold360AccountId = getHostProp('chatBotBold360AccountId');
	const chatBotBold360WebsiteId = getHostProp('chatBotBold360WebsiteId');
	const chatBotBold360ChatId = getHostProp('chatBotBold360ChatId');

	// Chat code BEGIN
	window._bcvma = window._bcvma || [];
	window.nanorep = window.nanorep || {};
	nanorep.floatingWidget = nanorep.floatingWidget || {};
	nanorep.floatingWidget._calls = nanorep.floatingWidget._calls || [];
	nanorep.floatingWidget._calls.push([
		'load',
		function () {
			setASHcontext();
			function setASHcontext() {
				nanorep.floatingWidget.setContext({
					Status: window.ashcontext,
					Domain: window.location.hostname,
				});
			}
		},
	]);
	var Memberid = Boolean(user.fitnessId) ? user.fitnessId : 'None';

	var ContractId = user.clientDetails?.contact || 'None';
	var WindowParameters =
		'customField_Memberid=' +
		Memberid +
		'&customField_ContractId=' +
		ContractId;
	_bcvma.push(['setAccountID', chatBotBold360AccountId]);
	_bcvma.push(['setParameter', 'WebsiteID', chatBotBold360WebsiteId]);
	_bcvma.push(['setParameter', 'VisitName', '']);
	_bcvma.push(['setParameter', 'VisitPhone', '']);
	_bcvma.push(['setParameter', 'VisitEmail', '']);
	_bcvma.push(['setParameter', 'VisitRef', '']);
	_bcvma.push(['setParameter', 'VisitInfo', '']);
	_bcvma.push(['setParameter', 'CustomUrl', '']);
	_bcvma.push(['setParameter', 'WindowParameters', WindowParameters]);
	_bcvma.push(['addFloat', { type: 'chat', id: chatBotBold360ChatId }]);
	_bcvma.push(['pageViewed']);
	var bcLoad = function () {
		if (window.bcLoaded) return;
		window.bcLoaded = true;
		var vms = document.createElement('script');
		vms.type = 'text/javascript';
		vms.async = true;
		vms.src = `https://vmss.boldchat.com/aid/${chatBotBold360AccountId}/bc.vms4/vms.js`;
		var s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(vms, s);
	};
	if (window.pageViewer && pageViewer.load) pageViewer.load();
	else if (document.readyState == 'complete') bcLoad();
	else if (window.addEventListener)
		window.addEventListener('load', bcLoad, false);
	else window.attachEvent('onload', bcLoad);

	window.ashcontext = user.fitnessId
		? user.subscriptionStatus
		: user.clientDetails?.contract
		? 'Eligible'
		: 'NotEligible';
	return (
		<noscript>
			<a
				href="http://www.boldchat.com"
				title="Live Support"
				target="_blank"
				rel="noreferrer"
			>
				<img
					alt="Live Support"
					src={`https://vms.boldchat.com/aid/${chatBotBold360AccountId}/bc.vmi?wdid=${chatBotBold360WebsiteId}&amp;vr=&amp;vn=&amp;vi=&amp;ve=&amp;vp=&amp;curl=`}
					border="0"
					width="1"
					height="1"
				/>
			</a>
		</noscript>
	);
};
export default ChatBot;
ChatBot.propTypes = {
	user: PropTypes.object.isRequired,
};
